import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Card from 'components/_shared/Card';
import CTAButton3 from 'components/_shared/buttons/CTAButton3';
import Overlay from 'components/_shared/Overlay';
import Notes from 'components/holders_list/components/ReferralComponents/Notes';
import StatusLabel from './StatusLabel';

import { Caseload, Referral, UniqueMemberInfo, User } from 'types';

import { formatDate } from 'util/index';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    arrow: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        backgroundColor: '#E7EFFE',
        borderRadius: '100%',
        color: '#062D7A',
        height: '32px',
        margin: '0 64px',
        minHeight: '32px',
        minWidth: '32px',
        width: '32px',
        '@media (max-width: 768px)': {
            margin: '0 32px'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '16px'
    },
    card: {
        position: 'relative',
        margin: '0 !important'
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        color: '#5A6A81',
        fontSize: '14px'
    },
    divider: {
        borderBottom: '1px solid #E0E0E0',
        marginTop: '16px'
    },
    fromTo: {
        fontWeight: 300,
        fontSize: '16px'
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        overflow: 'hidden'
    },
    name: {
        display: 'flex',
        gap: '4px',
        fontSize: '24px',
        fontWeight: 700,
        paddingLeft: '8px',
        '& span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '@media (max-width: 768px)': {
            flexDirection: 'column'
        }
    },
    nameOrgContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
    },
    photo: {
        borderRadius: '50%',
        width: '96px',
        height: '96px',
        objectFit: 'cover'
    },
    referralCard: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '20px'
    },
    referralInfoContainer: {
        display: 'flex'
    },
    referralInfo: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        gap: '8px'
    },
    referralNameOrg: {
        fontWeight: 700,
        fontSize: '24px'
    },
    referralSubOrg: {
        color: '#5A6A81',
        fontSize: '20px',
        fontWeight: 400
    }
}));

type ReferralCardProps = {
    acceptReferral: (referralID: number) => void;
    caseload: Caseload;
    direction: 'incoming' | 'outgoing';
    homeless: UniqueMemberInfo;
    profile: boolean;
    referral: Referral;
    rejectReferral: (referralID: number) => void;
    removeMemberFromMyMembers: (caseloadID: number, rejected?: boolean) => void;
    saveMemberToMyMembers: (
        caseloadID: number,
        memberID: number,
        accepted?: boolean
    ) => void;
    user: User;
};

export const determineStatus = ({
    caseloadStatus,
    caseloadUserID,
    direction,
    isAccepted,
    isRejected,
    isRereferred,
    orgID,
    partnerID,
    profileStage,
    refereeID,
    userID
}: {
    caseloadStatus: string;
    caseloadUserID: number;
    direction: string;
    isAccepted: boolean;
    isRejected: boolean;
    isRereferred: boolean;
    partnerID: number;
    orgID: number;
    profileStage: string;
    refereeID: number | null;
    userID: number;
}) => {
    if (direction === 'incoming') {
        if (refereeID === userID) {
            if (profileStage === 'EXITED') return 'Unenrolled';
            if (
                isAccepted &&
                caseloadStatus === 'active' &&
                caseloadUserID === userID
            ) {
                return 'Accepted by You';
            }
            if (
                isRejected &&
                caseloadStatus === 'cancelled' &&
                caseloadUserID === userID
            ) {
                return 'Rejected by You';
            }
            if (isAccepted) return 'Accepted';
            if (isRereferred && !isRejected) return 'Expired';
            if (!isAccepted && !isRereferred && !isRejected) {
                return caseloadStatus === 'active'
                    ? 'Active Caseload'
                    : 'Pending';
            }
        } else if (orgID === partnerID) {
            if (
                isAccepted &&
                caseloadStatus === 'active' &&
                caseloadUserID === userID
            ) {
                return 'Accepted';
            }
            if (caseloadStatus === 'cancelled' && caseloadUserID === userID) {
                return 'Rejected By You';
            }
            if (isRejected) return 'Rejected';
            if (
                isAccepted &&
                !isRereferred &&
                caseloadStatus === 'pending' &&
                caseloadUserID !== userID
            ) {
                return 'Org Accepted';
            }
            if (!isAccepted && isRereferred && !isRejected) return 'Expired';
            if (
                isAccepted &&
                isRereferred &&
                caseloadStatus !== 'active' &&
                caseloadUserID !== userID
            ) {
                return 'Org Accepted';
            }
            if (profileStage === 'EXITED') return 'Unenrolled';
            if (!isAccepted && !isRereferred && !isRejected) {
                return caseloadStatus === 'active'
                    ? 'Active Caseload'
                    : 'Pending';
            }
        }
    }

    if (isAccepted) return 'Accepted';
    if (isRereferred) return 'Expired';
    if (isRejected) return 'Rejected';
    if (profileStage === 'EXITED' && caseloadStatus === 'active')
        return 'Active Caseload';
    if (profileStage === 'EXITED' && caseloadStatus !== 'active')
        return 'Unenrolled';

    return 'Pending';
};

const handleActionWithDelay = async (
    actionFunction: any,
    successCallback: () => void,
    setLoading: (loading: boolean) => void,
    setShowOverlay: (showOverlay: boolean) => void
) => {
    setLoading(true);
    setShowOverlay(true);

    await new Promise((resolve) => {
        actionFunction();
        setTimeout(resolve, 1000);
    });

    setLoading(false);
    successCallback();
    setTimeout(() => setShowOverlay(false), 2000);
};

const ReferralCard: React.FC<ReferralCardProps> = ({
    acceptReferral,
    caseload,
    direction,
    profile,
    homeless,
    referral,
    rejectReferral,
    removeMemberFromMyMembers,
    saveMemberToMyMembers,
    user
}) => {
    const classes = useStyles();
    const history = useHistory();

    const {
        id: caseloadID,
        status: caseloadStatus,
        user_id: caseloadUserID
    } = caseload || {};
    const { mobile_photo, name, profile_stage } = homeless || {};
    const {
        created_at,
        homeless_id,
        id: referralID,
        is_accepted,
        is_rejected,
        is_re_referred,
        note,
        organization,
        referee,
        referee_id,
        referrer
    } = referral;
    const { name: referrerName, organization_name: referrerOrg } =
        referrer || {};
    const { name: refereeName, organization_name: refereeOrg } = referee || {};
    const { id: orgID, name: orgName } = organization || {};
    const { id: userID, name: userName, partner } = user;

    const [loading, setLoading] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const status = useMemo(
        () =>
            determineStatus({
                caseloadStatus,
                caseloadUserID,
                direction,
                isAccepted: is_accepted,
                isRejected: is_rejected,
                isRereferred: is_re_referred,
                orgID,
                partnerID: partner?.id,
                profileStage: profile_stage,
                refereeID: referee_id,
                userID
            }),
        [
            caseloadStatus,
            caseloadUserID,
            direction,
            is_accepted,
            is_re_referred,
            is_rejected,
            orgID,
            partner,
            profile_stage,
            referee_id,
            userID
        ]
    );

    const handleAcceptReferral = useCallback(() => {
        const accept = async () => {
            await handleActionWithDelay(
                () => {
                    acceptReferral(referralID);
                    saveMemberToMyMembers(caseloadID, homeless_id, true);
                },
                () => setAccepted(true),
                setLoading,
                setShowOverlay
            );
        };

        accept();
    }, [
        acceptReferral,
        caseloadID,
        homeless_id,
        referralID,
        saveMemberToMyMembers
    ]);

    const handleRejectReferral = useCallback(() => {
        const reject = async () => {
            await handleActionWithDelay(
                () => {
                    rejectReferral(referralID);
                    removeMemberFromMyMembers(caseloadID, true);
                },
                () => setRejected(true),
                setLoading,
                setShowOverlay
            );
        };

        reject();
    }, [caseloadID, referralID, rejectReferral, removeMemberFromMyMembers]);

    const handleViewProfile = useCallback(() => {
        history.push(`/holders/${homeless_id}`);
    }, [history, homeless_id]);

    const shouldShowButtons = () => {
        return (
            status === 'Pending' &&
            ((orgID === partner?.id && referrerName !== userName) ||
                referee_id === userID)
        );
    };

    if (!homeless) {
        return null;
    }

    return (
        <Card id="referral-card" className={classes.card}>
            {(accepted || loading || rejected) && showOverlay && (
                <Overlay
                    loading={loading}
                    text={
                        accepted
                            ? 'Referral accepted!'
                            : rejected
                            ? 'Referral rejected!'
                            : 'Loading...'
                    }
                />
            )}
            <div className={classes.referralCard}>
                {!profile && (
                    <div className={classes.infoContainer}>
                        <img
                            className={classes.photo}
                            src={mobile_photo}
                            alt="homeless"
                        />
                        <div className={classes.nameContainer}>
                            <div className={classes.name}>
                                <span>{name}</span>
                            </div>
                            <CTAButton3
                                arrow
                                invert
                                tighter
                                onClick={handleViewProfile}
                            >
                                View Profile
                            </CTAButton3>
                        </div>
                    </div>
                )}
                <div className={classes.referralInfoContainer}>
                    <div className={classes.referralInfo}>
                        <span className={classes.fromTo}>From</span>
                        <div className={classes.nameOrgContainer}>
                            <span className={classes.referralNameOrg}>
                                {referrerName === userName
                                    ? 'You'
                                    : referrerName}
                            </span>
                            <span className={classes.referralSubOrg}>
                                {referrerOrg}
                            </span>
                        </div>
                    </div>
                    <div className={classes.arrow}>
                        <i className="fas fa-arrow-right" />
                    </div>
                    <div className={classes.referralInfo}>
                        <span className={classes.fromTo}>To</span>
                        {refereeName && refereeOrg ? (
                            <>
                                <span className={classes.referralNameOrg}>
                                    {refereeName === userName
                                        ? 'You'
                                        : refereeName}
                                </span>
                                <span className={classes.referralSubOrg}>
                                    {refereeOrg}
                                </span>
                            </>
                        ) : (
                            <span className={classes.referralNameOrg}>
                                {orgName}
                            </span>
                        )}
                    </div>
                </div>
                <div className={classes.dateContainer}>
                    <span className={classes.date}>
                        <i className="far fa-calendar-alt" />
                        Created on: {formatDate(created_at)}
                    </span>
                    <StatusLabel status={status} />
                </div>
                {note && (
                    <>
                        <span className={classes.divider} />
                        <Notes note={note} />
                    </>
                )}
                {shouldShowButtons() && (
                    <>
                        <span className={classes.divider} />
                        <div className={classes.buttonContainer}>
                            <CTAButton3
                                invert
                                tight
                                cancel
                                onClick={handleRejectReferral}
                            >
                                Reject Referral
                            </CTAButton3>
                            <CTAButton3 onClick={handleAcceptReferral}>
                                Accept
                            </CTAButton3>
                        </div>
                    </>
                )}
            </div>
        </Card>
    );
};

export default ReferralCard;
