import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Caseload, Referral, UniqueMemberInfo, User } from 'types';

import LoadingCircle from 'components/_shared/LoadingCircle';
import Nav from 'components/_shared/NavSelector';
import ReferralCard from './components/ReferralCard';

import { isObjectEmpty } from 'util/index';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        margin: '24px auto',
        maxWidth: '640px',
        width: '90%',
        '@media (max-width: 768px)': {
            maxWidth: '100%'
        }
    },
    referralsBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '95%'
    },
    referralsPage: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '2rem',
        height: '100vh',
        overflow: 'auto'
    },
    referralsTitle: {
        paddingTop: '10px'
    }
}));

type ReferralsTabProps = {
    acceptReferral: (referralID: number) => void;
    caseloads: Caseload[];
    fetchCaseloads: (userID: number) => void;
    fetchReferrals: (userID: number, orgID?: number) => void;
    incomingReferrals: Referral[] | null;
    isFetchingCaseloads: boolean;
    isFetchingIncomingReferrals: boolean;
    isFetchingOutgoingReferrals: boolean;
    outgoingReferrals: Referral[] | null;
    uniqueMemberInfo: UniqueMemberInfo[] | null;
    rejectReferral: (referralID: number) => void;
    removeMemberFromMyMembers: (caseloadID: number, rejected?: boolean) => void;
    saveMemberToMyMembers: (
        caseloadID: number,
        memberID: number,
        accepted?: boolean
    ) => void;
    setLayoutTitles: (title: string, subtitle: string) => void;
    user: User;
};

const ReferralsTab: React.FC<ReferralsTabProps> = ({
    acceptReferral,
    caseloads,
    fetchCaseloads,
    fetchReferrals,
    incomingReferrals,
    isFetchingCaseloads,
    isFetchingIncomingReferrals,
    isFetchingOutgoingReferrals,
    outgoingReferrals,
    uniqueMemberInfo,
    rejectReferral,
    removeMemberFromMyMembers,
    saveMemberToMyMembers,
    setLayoutTitles,
    user
}) => {
    const classes = useStyles();

    const [active, setActive] = useState('Outgoing Referrals');

    const direction = active === 'Outgoing Referrals' ? 'outgoing' : 'incoming';

    const isLoading =
        !incomingReferrals ||
        !outgoingReferrals ||
        !uniqueMemberInfo ||
        !user.id ||
        isFetchingCaseloads ||
        isFetchingIncomingReferrals ||
        isFetchingOutgoingReferrals;

    const navItems = ['Outgoing Referrals', 'Incoming Referrals'];

    const referralsToRender = useMemo(() => {
        return active === 'Outgoing Referrals'
            ? outgoingReferrals || []
            : incomingReferrals || [];
    }, [active, incomingReferrals, outgoingReferrals]);

    const matchHomelessToReferral = (referral: Referral) => {
        const homeless = (uniqueMemberInfo as UniqueMemberInfo[]).find(
            (homeless: UniqueMemberInfo) => homeless.id === referral.homeless_id
        );

        if (!homeless) {
            return null;
        }

        return homeless;
    };

    const matchHomelessToCaseload = (referral: Referral) => {
        const caseload = caseloads.find(
            (caseload) => caseload.homeless_id === referral.homeless_id
        );

        if (!caseload) {
            return null;
        }

        return caseload;
    };

    useEffect(() => {
        if (!caseloads && !isFetchingCaseloads) {
            fetchCaseloads(user.id);
        }
    }, [caseloads, fetchCaseloads, isFetchingCaseloads, user]);

    useEffect(() => {
        if (
            !incomingReferrals &&
            !isFetchingIncomingReferrals &&
            user.id &&
            user.partner?.id
        ) {
            fetchReferrals(user.id, user.partner?.id);
        }
    }, [fetchReferrals, incomingReferrals, isFetchingIncomingReferrals, user]);

    useEffect(() => {
        if (!outgoingReferrals && !isFetchingOutgoingReferrals && user.id) {
            fetchReferrals(user.id);
        }
    }, [fetchReferrals, isFetchingOutgoingReferrals, outgoingReferrals, user]);

    // TODO: refresh the incoming and outgoing referrals when the page is revisited

    useEffect(() => {
        if (!isObjectEmpty(user)) {
            setLayoutTitles('', 'Referrals');
        }
    }, [setLayoutTitles, user]);

    if (isLoading) {
        return <LoadingCircle />;
    }

    return (
        <div className={classes.referralsPage}>
            <Helmet title="Referrals" />
            <h1 className={classes.referralsTitle}>Your Referrals</h1>
            <div className={classes.referralsBody}>
                <Nav
                    active={active}
                    navItems={navItems}
                    setActive={setActive}
                />
                <div className={classes.cardContainer}>
                    {(referralsToRender.length ?? 0) > 0 ? (
                        referralsToRender?.map((referral) => (
                            <ReferralCard
                                key={referral.id}
                                acceptReferral={acceptReferral}
                                caseload={
                                    matchHomelessToCaseload(
                                        referral
                                    ) as Caseload
                                }
                                direction={direction}
                                homeless={
                                    matchHomelessToReferral(
                                        referral
                                    ) as UniqueMemberInfo
                                }
                                profile={false}
                                referral={referral}
                                rejectReferral={rejectReferral}
                                removeMemberFromMyMembers={
                                    removeMemberFromMyMembers
                                }
                                saveMemberToMyMembers={saveMemberToMyMembers}
                                user={user}
                            />
                        ))
                    ) : (
                        <div style={{ margin: 'auto' }}>
                            <h1>No referrals found.</h1>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReferralsTab;
