import ActionSteps from './ActionSteps';
// import Referrals from './Referrals';
import SDOHStatus from './SDOHStatus';

import {
    Caseload,
    GoalActions,
    Homeless,
    NewGoalActions,
    Referral,
    TouchpointAnswers,
    TouchpointQuestions,
    User
} from 'types';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    caseManagementContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '80px',
        padding: '30px 12% 60px 12%'
    }
}));

type CaseManagementProps = {
    acceptReferral: (referralID: number) => void;
    addStatus: boolean;
    assignedBonusAmount: number;
    caseloads: Caseload[];
    exited: boolean;
    fetchTouchpointAnswersById: (id: number) => void;
    goalActions: GoalActions[];
    holder: Homeless;
    isEditingGoalAction: boolean;
    isFetchingTouchpointAnswersById: boolean;
    isSubmittingTouchpointAnswers: boolean;
    maxBonusAmount: number;
    memberReferrals: Referral[];
    newHomelessGoalActions: NewGoalActions[];
    rejectReferral: (referralID: number) => void;
    remainingBonusAmount: number;
    removeMemberFromMyMembers: (caseloadID: number, rejected?: boolean) => void;
    saveMemberToMyMembers: (
        caseloadID: number,
        memberID: number,
        accepted?: boolean
    ) => void;
    setAddStatus: (value: boolean) => void;
    setReferMemberPopUp: (value: boolean) => void;
    setShowToast: (value: boolean) => void;
    setToastMessage: (value: string) => void;
    touchpointAnswers: TouchpointAnswers[];
    touchpointAnswersById: number[];
    touchpointQuestions: TouchpointQuestions[];
    touchpointQuestionQOLs: number[];
    updateProfileCompletedItems: (profileCompletedItems: string) => void;
    user: User;
};

const CaseManagement: React.FC<CaseManagementProps> = ({
    goalActions,
    ...props
}) => {
    const classes = useStyles();

    // const referralsProps = {
    //     acceptReferral: props.acceptReferral,
    //     caseloads: props.caseloads,
    //     holder: props.holder,
    //     memberReferrals: props.memberReferrals,
    //     name: props.holder.name,
    //     rejectReferral: props.rejectReferral,
    //     removeMemberFromMyMembers: props.removeMemberFromMyMembers,
    //     saveMemberToMyMembers: props.saveMemberToMyMembers,
    //     setReferMemberPopUp: props.setReferMemberPopUp,
    //     user: props.user
    // };

    const statusProps = {
        goalActions,
        ...props
    };

    return (
        <div className={classes.caseManagementContainer}>
            {!props.exited && <ActionSteps {...statusProps} />}
            <SDOHStatus {...statusProps} />
            {/* <Referrals {...referralsProps} /> */}
        </div>
    );
};

export default CaseManagement;
