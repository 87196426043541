import CONSTANTS from '../constants/constants';

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 7);
const localDueDate = new Date(
    currentDate.getTime() - currentDate.getTimezoneOffset() * 60000
).toISOString();

const initialState = {
    intakeAgreed: false,
    intakeBeaconId: '',
    intakeBirthDay: '',
    intakeDeploymentId: '',
    intakeEmail: '',
    intakeGoals: [
        {
            description: 'Complete Member Registration',
            customGoals: 'Meet needs with a Samaritan Membership',
            goals: [],
            quality_of_life_measure_id: [],
            amount: 10,
            due_at: localDueDate,
            goal: {
                description: 'Meet needs with a Samaritan Membership',
                actions: [
                    {
                        action_type: 'one_time',
                        description: 'Complete Member Registration',
                        quality_of_life_measure_id: [],
                        stake_match_amount: 10,
                        due_at: new Date(
                            new Date().setDate(new Date().getDate() + 7)
                        ).toISOString()
                    }
                ],
                needs: []
            },
            default: true
        }
    ],
    intakeGuideMessages: ['Action Steps', 'Public Info'],
    intakeGuideSteps: Array(4)
        .fill(null)
        .map((_, minIntakeStep) => minIntakeStep + 1),
    intakeIcebreaker: '',
    intakeId: null,
    intakeInterviewer: '',
    intakeInterviewerId: '',
    intakeIsSubmitted: false,
    intakeIsSubmitting: false,
    intakeLifeStory: '',
    intakeNeeds: [],
    intakePhoneNo: '',
    intakePrivateFirstName: null,
    intakePrivateFullName: '',
    intakePrivateLastName: null,
    intakePrivateMiddleName: '',
    intakeProfileName: '',
    intakeProfilePic: null,
    intakeProgressWidth: [],
    intakeQuestionsAndAnswers: {},
    intakeRelevantId: '',
    intakeSkills: [],
    intakeStep: 1,
    intakeTouchpoints: [],
    intakeTouchpointIsSubmitted: false,
    intakeTouchpointIsSubmitting: false,
    maxIntakeStep: 5,
    minIntakeStep: 1
};

export default function intake_reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONSTANTS.DECREMENT_INTAKE_STEP:
            if (!action.payload) {
                if (state.intakeStep <= state.minIntakeStep) return state;

                const remainingGuideStepsDec = state.intakeGuideSteps.filter(
                    (step) => step !== state.intakeStep
                );

                return {
                    ...state,
                    intakeStep: state.intakeStep - 1,
                    intakeGuideSteps: remainingGuideStepsDec
                };
            } else {
                if (action.payload <= state.minIntakeStep) return state;

                const remainingGuideStepsDec = [
                    action.payload - 1,
                    ...state.intakeGuideSteps
                ];

                return {
                    ...state,
                    intakeStep: action.payload - 1,
                    intakeGuideSteps: remainingGuideStepsDec
                };
            }
        case CONSTANTS.GET_INTAKE_SUBMIT:
            return {
                ...state,
                intakeIsSubmitting: true,
                intakeIsSubmitted: false
            };
        case CONSTANTS.GET_INTAKE_TOUCHPOINT_SUBMIT:
            return {
                ...state,
                intakeTouchpointIsSubmitting: true,
                intakeTouchpointIsSubmitted: false
            };
        case CONSTANTS.INCREMENT_INTAKE_STEP:
            if (state.intakeStep >= state.maxIntakeStep) {
                return state;
            }

            const remainingGuideStepsInc = state.intakeGuideSteps.filter(
                (step) => step !== state.intakeStep
            );

            const existingItemIndex = state.intakeProgressWidth.findIndex(
                (item: { step: number }) => item.step === state.intakeStep
            );

            if (existingItemIndex !== -1) {
                const newIntakeProgressWidth = [...state.intakeProgressWidth];
                (
                    newIntakeProgressWidth[existingItemIndex] as {
                        width: number;
                    }
                ).width = action.payload;

                return {
                    ...state,
                    intakeStep: state.intakeStep + 1,
                    intakeProgressWidth: newIntakeProgressWidth,
                    intakeGuideSteps: remainingGuideStepsInc
                };
            } else {
                return {
                    ...state,
                    intakeStep: state.intakeStep + 1,
                    intakeProgressWidth: [
                        ...state.intakeProgressWidth,
                        {
                            step: state.intakeStep,
                            width: action.payload || 0
                        }
                    ],
                    intakeGuideSteps: remainingGuideStepsInc
                };
            }
        case CONSTANTS.RESET_INTAKE:
            return {
                ...initialState,
                intakeQuestionsAndAnswers: {}
            };
        case CONSTANTS.SET_INTAKE_BEACON_ID:
            return {
                ...state,
                intakeBeaconId: action.payload
            };
        case CONSTANTS.SET_INTAKE_BIRTH_DAY:
            return {
                ...state,
                intakeBirthDay: action.payload
            };
        case CONSTANTS.SET_INTAKE_DEPLOYMENT_ID:
            return {
                ...state,
                intakeDeploymentId: action.payload
            };
        case CONSTANTS.SET_INTAKE_EMAIL:
            return {
                ...state,
                intakeEmail: action.payload
            };
        case CONSTANTS.SET_INTAKE_ERROR:
            return {
                ...state,
                intakeIsSubmitting: false,
                intakeIsSubmitted: false
            };
        case CONSTANTS.SET_INTAKE_GOALS:
            return {
                ...state,
                intakeGoals: action.payload || []
            };
        case CONSTANTS.SET_INTAKE_ICEBREAKER:
            return {
                ...state,
                intakeIcebreaker: action.payload
            };
        case CONSTANTS.SET_INTAKE_ORG_ID:
            return {
                ...state,
                intakeOrgId: action.payload
            };
        case CONSTANTS.SET_INTAKE_PHONE_NUMBER:
            return {
                ...state,
                intakePhoneNo: action.payload
            };
        case CONSTANTS.SET_INTAKE_PRIVATE_FIRST_NAME:
            return {
                ...state,
                intakePrivateFirstName: action.payload
            };
        case CONSTANTS.SET_INTAKE_PRIVATE_FULL_NAME:
            return {
                ...state,
                intakePrivateFullName: action.payload
            };
        case CONSTANTS.SET_INTAKE_PRIVATE_LAST_NAME:
            return {
                ...state,
                intakePrivateLastName: action.payload
            };
        case CONSTANTS.SET_INTAKE_PRIVATE_MIDDLE_NAME:
            return {
                ...state,
                intakePrivateMiddleName: action.payload
            };
        case CONSTANTS.SET_INTAKE_PROFILE_PICTURE:
            return {
                ...state,
                intakeProfilePic: action.payload
            };
        case CONSTANTS.SET_INTAKE_PROFILE_NAME:
            return {
                ...state,
                intakeProfileName: action.payload
            };
        case CONSTANTS.SET_INTAKE_QUESTION_ANSWER:
            let answerMap = {
                ...state.intakeQuestionsAndAnswers,
                [action.payload.questionId]: {
                    answer: action.payload.answer,
                    color: action.payload.color
                }
            };
            return {
                ...state,
                intakeQuestionsAndAnswers: answerMap
            };
        case CONSTANTS.SET_INTAKE_RELEVANT_ID:
            return {
                ...state,
                intakeRelevantId: action.payload
            };
        case CONSTANTS.SET_INTAKE_SUBMIT:
            return {
                ...state,
                intakeBeaconId: action.payload.beaconId,
                intakeId: action.payload.id,
                intakeIsSubmitting: false,
                intakeIsSubmitted: true
            };
        case CONSTANTS.SET_INTAKE_TOUCHPOINT_ANSWER:
            const { questionId, answer } = action.payload;
            const existingTouchpointIndex = state.intakeTouchpoints.findIndex(
                (t: { questionId: number }) => t.questionId === questionId
            );

            if (existingTouchpointIndex !== -1) {
                if (answer.length) {
                    const updatedTouchpoints: Array<{
                        questionId: number;
                        answer: string;
                    }> = [...state.intakeTouchpoints];
                    updatedTouchpoints[existingTouchpointIndex] = {
                        ...updatedTouchpoints[existingTouchpointIndex],
                        answer
                    };
                    return {
                        ...state,
                        intakeTouchpoints: updatedTouchpoints
                    };
                } else {
                    const filteredTouchpoints = state.intakeTouchpoints.filter(
                        (t: { questionId: number }) =>
                            t.questionId !== questionId
                    );
                    return {
                        ...state,
                        intakeTouchpoints: filteredTouchpoints
                    };
                }
            }

            if (answer.length) {
                return {
                    ...state,
                    intakeTouchpoints: [
                        ...state.intakeTouchpoints,
                        action.payload
                    ]
                };
            }

            return state;
        case CONSTANTS.SET_INTAKE_TOUCHPOINT_ERROR:
            return {
                ...state,
                intakeTouchpointIsSubmitting: false,
                intakeTouchpointIsSubmitted: false
            };
        case CONSTANTS.SET_INTAKE_TOUCHPOINT_SUBMIT:
            return {
                ...state,
                intakeTouchpointIsSubmitting: false,
                intakeTouchpointIsSubmitted: true,
                intakeStep: state.maxIntakeStep
            };
        case CONSTANTS.SET_VIEW_GUIDE_MESSAGE:
            const messageType = action.payload;

            const remainingGuideSteps = state.intakeGuideMessages.filter(
                (step) => step !== messageType
            );

            return {
                ...state,
                intakeGuideMessages: remainingGuideSteps
            };
        case CONSTANTS.TOGGLE_INTAKE_AGREED:
            return {
                ...state,
                intakeAgreed: !state.intakeAgreed
            };
        default:
            return state;
    }
}
